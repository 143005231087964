/* Mixins */
.view-dashboard-component .dashboard-header {
  width: 100%;
  height: 48px;
  line-height: 48px;
}
.view-dashboard-component .dashboard-header .ant-col {
  height: 48px;
}
.view-dashboard-component .ant-layout-content {
  overflow: auto;
}
.view-dashboard-component .collapsed-wrapper {
  cursor: pointer;
  padding: 12px 0;
  font-size: 18px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  border-top: solid 1px #1F252E;
}
.view-dashboard-component .collapsed-wrapper:hover {
  color: #2393cd;
}
.view-dashboard-component .dashboard-sider {
  background: #252B36;
  min-height: calc(100vh - 48px) !important;
  height: calc(100vh - 48px);
  overflow: auto;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root {
  color: #96A1B3;
  height: calc(100vh - 48px - 52px);
  overflow: auto;
  background: #252B36;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .anticon {
  margin-left: -2px;
  margin-top: -4px;
  font-size: 18px;
  vertical-align: middle;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-divider {
  background-color: #1F252E;
  margin: 0;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub {
  background: #191F28;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-selected, .view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub-item-selected {
  background-color: transparent;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-selected span, .view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-selected i, .view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub-item-selected span, .view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-sub-item-selected i {
  color: #2393cd;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-wrapper, .view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-sub-menu-item-wrapper .ant-menu-submenu-title {
  border-left: solid 2px #252B36;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-menu-item-wrapper__selected {
  border-left-color: #2393cd;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-sub-menu-item-wrapper__selected .ant-menu-submenu-title {
  border-left-color: #2393cd;
}
.view-dashboard-component .dashboard-sider .ant-menu.ant-menu-root .ant-sub-menu-item-wrapper__selected .ant-menu-submenu-title i {
  color: #2393cd;
}
.view-dashboard-component .dashboard-header {
  z-index: 1;
  color: #fff;
  background: #131518;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(43, 53, 62, 0.6);
          box-shadow: 0px 0px 8px 0px rgba(43, 53, 62, 0.6);
  padding: 0;
}
.view-dashboard-component .dashboard-header .header-logo {
  padding-left: 24px;
}
.view-dashboard-component .dashboard-header .header-logo .header-title {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 500;
}
.view-dashboard-component .dashboard-header .username-menu {
  height: 48px;
  padding-right: 20px;
}
.view-dashboard-component .dashboard-header .username-menu .username-menu-email {
  display: inline-block;
  max-width: 200px;
  font-weight: 400;
}
.view-dashboard-component .dashboard-header .username-menu .user-header {
  font-size: 30px;
  vertical-align: top;
  margin-top: 9px;
  margin-right: 4px;
}
.view-dashboard-component .dashboard-view {
  padding: 0;
  height: calc(100vh - 48px);
  background: #030f27;
  overflow: auto;
  overflow-x: auto !important;
  position: relative;
}