
.v-charts-component-loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(255, 255, 255, .9);
}
.v-charts-component-loading.jtlReportTheme {
  background-color: #191f28;
}
.v-charts-mask-status {
  -webkit-filter: blur(1px);
          filter: blur(1px);
}
.v-charts-component-loading .circular {
  width: 42px;
  height: 42px;
  -webkit-animation: loading-rotate 2s linear infinite;
          animation: loading-rotate 2s linear infinite;
}
.v-charts-component-loading .path {
  -webkit-animation: loading-dash 1.5s ease-in-out infinite;
          animation: loading-dash 1.5s ease-in-out infinite;
  stroke-dasharray: 90,150;
  stroke-dashoffset: 0;
  stroke-width: 2;
  stroke: #20a0ff;
  stroke-linecap: round;
}
@-webkit-keyframes loading-rotate {
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@keyframes loading-rotate {
100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}
@-webkit-keyframes loading-dash {
0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
}
50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
}
100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
}
}
@keyframes loading-dash {
0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
}
50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -40px;
}
100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -120px;
}
}
