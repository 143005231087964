@charset "UTF-8";
/* Mixins */
/* Global transition */
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.28s;
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.fade-transform-leave-active,
.fade-transform-enter-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.fade-transform-enter {
  opacity: 0;
  -webkit-transform: translateX(-30px);
          transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  -webkit-transform: translateX(30px);
          transform: translateX(30px);
}

.breadcrumb-enter-active,
.breadcrumb-leave-active {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}

.breadcrumb-move {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.breadcrumb-leave-active {
  position: absolute;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  -webkit-transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter, .slide-fade-leave-to {
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  opacity: 0;
}

::-webkit-scrollbar {
  z-index: 11;
  width: 6px;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  width: 6px;
  background: rgba(144, 147, 153, 0.5);
}
::-webkit-scrollbar-track-piece {
  width: 6px;
}

.topo-panel {
  height: 100%;
  width: 100%;
}

/* Global scss */
body {
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-family: Microsoft YaHei, PingFang SC, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Helvetica, Hiragino Sans GB, Arial, sans-serif;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

a,
a:focus,
a:hover {
  color: inherit;
  outline: none;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

div:focus {
  outline: none;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.hide {
  display: none;
}

.inline-block {
  display: inline-block;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.text-ellipsis, .parking-card-wrapper .parking-card-item .parking-card-item-number {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  overflow: hidden;
}

.h-center {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  -ms-transform: translateX(-50%);
}

.margin-0 {
  margin: 0;
}

.margin-16 {
  margin: 16px;
}

.tab-margin-16 {
  margin: 0 16px 16px 16px;
}

/* Customer component scss */
/* page component */
.page-header .page-breadcrumb {
  padding: 10px 20px;
  background-color: #fff;
}
.page-header .header-title {
  font-size: 16px;
  font-weight: bold;
  color: #373d41;
  vertical-align: bottom;
  line-height: 40px;
  padding: 8px 0 4px 24px;
  background: #fff;
  margin-bottom: 16px;
}
.page-header .header-title a {
  vertical-align: middle;
}
.page-header .header-title i.anticon {
  font-size: 22px;
}
.page-header .header-sub-title {
  font-weight: bold;
  font-size: 20px;
  margin-left: 24px;
  color: #373d41;
}

.page-table-search {
  margin-bottom: 16px;
  padding: 16px 24px;
  background-color: #fff;
}
.page-table-search__header {
  padding: 0 12px 16px 0;
  border-bottom: solid 1px #edeef0;
  margin-bottom: 8px;
}
.page-table-search__input {
  max-width: 200px;
  vertical-align: top;
}
.page-table-search__add-btn {
  float: right;
  margin-top: 8px;
}

.page-table-search-small {
  padding: 8px 0;
}

.page-body-bg-white {
  background-color: #fff;
}

.page-body-margin {
  margin: 24px 24px 0 24px;
}

.page-body-margin-left-right {
  margin: 0 24px;
}

.page-body-margin-left-16 {
  margin-left: 16px;
}

.page-top-menu {
  padding: 0 0 16px 16px;
}

.form-edit-component .ant-modal {
  width: 40vw !important;
  min-width: 600px;
  max-width: 1000px;
}

.form-edit-component-small .ant-modal {
  width: 30vw !important;
  max-width: 800px;
}

.form-edit-component-large .ant-modal {
  width: 70vw !important;
  max-width: 1200px;
}

.form-edit-component-max .ant-modal {
  width: 80vw !important;
  max-width: 4320px;
}

/* jtl common */
.jtl-link,
.jtl-link:hover,
.ant-table td a,
.ant-table td a:hover {
  color: #2393cd;
  cursor: pointer;
}

.jtl-update-link {
  color: #faad14;
}

.jtl-del-link {
  color: #e07460 !important;
}

.jtl-edit-link {
  color: #e59940 !important;
}

/* status-circle */
.status-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #888;
}

.active-circle {
  background: #0070cc;
}

.online-circle,
.circle-true,
.true-circle {
  background: #1e8e3e;
}

.offline-circle,
.circle-false,
.false-circle {
  background: #888;
}

.jtl-tag-wrapper {
  display: inline-block;
  padding: 4px;
}

.table-query-card {
  margin-bottom: 16px;
}
.table-query-card .table-query-item {
  display: inline-block;
  margin-right: 4px;
  vertical-align: top;
}
.table-query-card .table-query-item__search-label {
  display: inline-block;
  width: 100px;
  text-align: right;
  margin-right: 4px;
}
.table-query-card .table-query-item__search-control {
  vertical-align: middle;
}

.table-query-card .ant-row {
  margin-bottom: 8px;
}
.table-query-card .ant-row span.query-lable {
  display: inline-block;
  width: 100px;
}
.table-query-card .ant-row .query-control {
  width: calc(100% - 104px) !important;
}

.hight-light-word {
  color: #dc4f59;
}

.resize-table-th {
  position: relative;
}

.table-draggable-handle {
  /* width: 10px !important; */
  height: 100% !important;
  cursor: col-resize;
  -ms-touch-action: none;
      touch-action: none;
  border: none;
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -5px;
}

.draggable-wrapper .ant-table-row {
  cursor: move;
}
.draggable-wrapper .ant-table-row * {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.action-update {
  color: #faad14 !important;
}

.action-delete {
  color: #dc4f59 !important;
}

.table-custom-expand-wrapper {
  position: absolute;
  width: 14px;
}
.table-custom-expand-wrapper .table-custom-expand-icon {
  cursor: pointer;
}

.table-custom-expand-label {
  margin-left: 20px;
}

.ant-table-row-level-1 {
  background-color: #fffbe6;
}
.ant-table-row-level-1 .table-custom-expand-wrapper {
  margin-left: 40px;
}
.ant-table-row-level-1 .table-custom-expand-label {
  margin-left: 60px;
}

.ant-table-row-level-2 {
  background-color: #f6ffe6;
}
.ant-table-row-level-2 .table-custom-expand-wrapper {
  margin-left: 60px;
}
.ant-table-row-level-2 .table-custom-expand-label {
  margin-left: 80px;
}

.ant-table-row-level-3 {
  background-color: #eaffe6;
}
.ant-table-row-level-3 .table-custom-expand-wrapper {
  margin-left: 80px;
}
.ant-table-row-level-3 .table-custom-expand-label {
  margin-left: 100px;
}

.ant-table-row-level-4 {
  background-color: #e6ffef;
}
.ant-table-row-level-4 .table-custom-expand-wrapper {
  margin-left: 100px;
}
.ant-table-row-level-4 .table-custom-expand-label {
  margin-left: 120px;
}

.ant-table-row-level-5 {
  background-color: #e6fffb;
}
.ant-table-row-level-5 .table-custom-expand-wrapper {
  margin-left: 120px;
}
.ant-table-row-level-5 .table-custom-expand-label {
  margin-left: 140px;
}

.ant-table-row-level-6 {
  background-color: #e6f6ff;
}
.ant-table-row-level-6 .table-custom-expand-wrapper {
  margin-left: 140px;
}
.ant-table-row-level-6 .table-custom-expand-label {
  margin-left: 160px;
}

.ant-table-row-level-7 {
  background-color: #e6f6ff;
}
.ant-table-row-level-7 .table-custom-expand-wrapper {
  margin-left: 160px;
}
.ant-table-row-level-7 .table-custom-expand-label {
  margin-left: 180px;
}

.ant-table-row-level-8 {
  background-color: #e6f6ff;
}
.ant-table-row-level-8 .table-custom-expand-wrapper {
  margin-left: 180px;
}
.ant-table-row-level-8 .table-custom-expand-label {
  margin-left: 200px;
}

.sider-tabs .ant-tabs-content {
  padding: 0 12px;
}

.transform-dialog .transform-header {
  margin-bottom: 16px;
}
.transform-dialog .transform-column {
  border: solid 1px #d9d9d9;
  height: 60vh;
  overflow-y: auto;
}
.transform-dialog .transform-column-middle {
  height: 60vh;
}
.transform-dialog .transform-column-no-border {
  border: none;
}
.transform-dialog .transform-name {
  display: inline-block;
  max-width: calc(100% - 90px);
  vertical-align: top;
}
.transform-dialog .transform-name-right {
  max-width: calc(100% - 80px);
}
.transform-dialog .right-close-icon {
  float: right;
  height: 32px;
  line-height: 32px;
  padding: 0 4px;
}
.transform-dialog .right-close-icon:hover {
  float: right;
  color: #2393cd;
}
.transform-dialog .ul-list {
  padding: 8px 0 8px 16px;
  max-height: 60vh;
  height: 100%;
  overflow-y: auto;
}
.transform-dialog .ul-list li {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
}
.transform-dialog .ul-list li .ant-checkbox-wrapper {
  width: 100%;
}
.transform-dialog .ul-list li:hover {
  background-color: #fffbf0;
}
.transform-dialog .ul-list .li-no-hover:hover {
  background-color: transparent;
}
.transform-dialog .ul-list-no-padding {
  padding: 0;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.ant-descriptions-title .description-title-button {
  margin-left: 16px;
  vertical-align: middle;
}

.width100 {
  width: 100% !important;
}

.text-desc {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.select-load-more {
  display: inline-block;
  padding: 4px 8px;
  cursor: pointer;
  margin-bottom: 4px;
}

.clickable {
  cursor: pointer;
}

.custom-loading-wrapper {
  position: relative;
}

.height100 {
  height: 100%;
}

.dark-card .ant-card-head {
  background-color: #202631;
  color: #fff;
}
.dark-card .ant-card-body {
  background-color: #191f28;
}
.dark-card .ant-menu-dark,
.dark-card .ant-menu-dark .ant-menu-sub {
  background: transparent;
}
.dark-card .ant-menu-dark .ant-menu-item a,
.dark-card .ant-menu-dark .ant-menu-sub .ant-menu-item a {
  color: #6c737b;
}
.dark-card .ant-menu-dark .ant-menu-item-selected,
.dark-card .ant-menu-dark .ant-menu-sub .ant-menu-item-selected {
  background-color: #344053;
}
.dark-card .ant-menu-dark .ant-menu-item-selected a,
.dark-card .ant-menu-dark .ant-menu-sub .ant-menu-item-selected a {
  color: #feffff;
}

.jtl-dark-query-component .table-query-item__search-label,
.jtl-dark-query-component .ant-calendar-range-picker-separator {
  color: #d9dcdf;
}
.jtl-dark-query-component .ant-select-selection {
  background-color: transparent;
}
.jtl-dark-query-component .ant-select-selection .ant-select-arrow {
  color: #797d80;
}
.jtl-dark-query-component .ant-input {
  border-color: #fff;
  color: #d9dcdf;
  background-color: #191f28;
}
.jtl-dark-query-component .ant-select .ant-select-selection-selected-value,
.jtl-dark-query-component .ant-select .ant-select-search__field {
  color: #d9dcdf;
}
.jtl-dark-query-component .ant-select .ant-input {
  border-color: #fff;
  color: #d9dcdf;
  background-color: #191f28;
}
.jtl-dark-query-component .ant-select .ant-select .ant-select-selection-selected-value,
.jtl-dark-query-component .ant-select .ant-select .ant-select-search__field {
  color: #d9dcdf;
}
.jtl-dark-query-component .ant-select .ant-select .ant-select-selection__clear {
  color: #d9dcdf;
}
.jtl-dark-query-component .ant-radio-wrapper {
  color: #ffffff;
}

.ant-select-dropdown-dark {
  background-color: #191f28;
  color: #d9dcdf;
}
.ant-select-dropdown-dark .ant-empty-description {
  color: #d9dcdf;
}
.ant-select-dropdown-dark .ant-select-dropdown-search {
  background: transparent;
}
.ant-select-dropdown-dark .ant-select-search__field {
  border-color: #fff;
  background-color: #191f28;
}
.ant-select-dropdown-dark .ant-select-tree,
.ant-select-dropdown-dark .ant-select-tree-title,
.ant-select-dropdown-dark .ant-select-dropdown-menu-item {
  color: #d9dcdf;
}
.ant-select-dropdown-dark .ant-select-tree li .ant-select-tree-node-content-wrapper:hover,
.ant-select-dropdown-dark .ant-select-dropdown-menu-item-active,
.ant-select-dropdown-dark .ant-select-dropdown-menu-item-selected {
  background-color: #fff;
  color: #333;
}
.ant-select-dropdown-dark .ant-select-tree li .ant-select-tree-node-content-wrapper:hover .ant-select-tree-title,
.ant-select-dropdown-dark .ant-select-dropdown-menu-item-active .ant-select-tree-title,
.ant-select-dropdown-dark .ant-select-dropdown-menu-item-selected .ant-select-tree-title {
  color: #333;
}
.ant-select-dropdown-dark .ant-select-tree-node-selected .ant-select-tree-title {
  color: #333;
}

.checkbox-dark {
  color: #d9dcdf;
}

.jtl-button-dark .ant-btn {
  border-radius: 2px;
  background-color: #455774;
  color: #fff;
  border: solid 1px #9a9ea2;
}

.jtl-button-dark2 .ant-btn {
  border-radius: 4px;
  background-color: rgba(29, 157, 206, 0.2);
  color: #D3EBFC;
  border: solid 1px #1F99C7;
}

.table-dark {
  background-color: #191f28;
}
.table-dark .ant-table-placeholder {
  background-color: #191f28;
}
.table-dark .ant-table-thead > tr > th,
.table-dark .ant-empty-description,
.table-dark .ant-pagination,
.table-dark .ant-pagination-item a,
.table-dark .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.table-dark .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  color: #d9dcdf;
}
.table-dark .ant-table-tbody > tr > td,
.table-dark .ant-table-thead > tr > th {
  border-bottom: 1px solid #222732;
}
.table-dark .ant-table {
  color: #949aa1;
  border: none;
}
.table-dark .ant-table-thead {
  color: #d9dcdf;
  background: #161c25 !important;
  background-color: #161c25 !important;
}
.table-dark .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body,
.table-dark .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.table-dark .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.table-dark .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.table-dark .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #161c25;
  color: #fff;
}
.table-dark .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
  border: 1px solid #3181c5;
  -webkit-box-shadow: 0px 0px 21px 0px rgba(39, 120, 189, 0.32);
          box-shadow: 0px 0px 21px 0px rgba(39, 120, 189, 0.32);
  border-radius: 8px;
}
.table-dark .ant-pagination-item-active {
  background: #288ee5;
  color: #fafafa;
}
.table-dark .ant-pagination.mini .ant-pagination-item {
  margin-left: 4px;
  color: #949aa1;
}
.table-dark .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border: 1px solid #3a4351;
}
.table-dark .ant-table-row-level-1 {
  background-color: transparent;
}

.ant-table .ant-table-thead {
  background-color: #F8F8F8;
}

.parking-table .ant-table {
  border-left: none;
  border-right: none;
  border-radius: 0;
}
.parking-table .ant-table .ant-table-body {
  margin: 0;
}

.parking-card-wrapper .sub-system-header-card {
  background-color: #fff;
  color: #000;
  margin-bottom: 16px;
}
.parking-card-wrapper .parking-card-item {
  min-width: 200px;
  padding: 24px 0;
}
.parking-card-wrapper .parking-card-item .parking-card-item-icon {
  height: 38px;
  width: 38px;
  margin-right: 20px;
  vertical-align: baseline;
}
.parking-card-wrapper .parking-card-item .parking-card-item-text-wrapper {
  display: inline-block;
}
.parking-card-wrapper .parking-card-item .parking-card-item-title {
  font-weight: bold;
  color: #585E65;
}
.parking-card-wrapper .parking-card-item .parking-card-item-number {
  font-size: 24px;
  max-width: 190px;
  display: inline-block;
  vertical-align: bottom;
  color: #363B40;
  font-weight: bold;
}
.parking-card-wrapper .parking-card-item .parking-card-item-unit {
  font-size: 12px;
  margin-left: 8px;
  margin-bottom: 6px;
  display: inline-block;
  color: #363B40;
}