/* Mixins */
.jtl-custom-gauge .ant-progress-circle-trail {
  stroke: #D9F1FF !important;
}
.jtl-custom-gauge .jtl-custom-gauge-percent-wrapper {
  line-height: 1.5;
}
.jtl-custom-gauge .jtl-custom-gauge-percent-wrapper .jtl-custom-gauge-percent {
  font-size: 24px;
  color: #47484D;
  font-weight: 400;
}
.jtl-custom-gauge .jtl-custom-gauge-percent-wrapper .jtl-custom-gauge-desc {
  color: #85888C;
}