/* Mixins */
.view-bizier-curve-arrow {
  height: 100%;
  width: 100%;
  position: relative;
}
.view-bizier-curve-arrow .passby {
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: white;
  border: 1px solid #220edf;
  cursor: move;
}