/* Mixins */
.jtl-qrcode-component {
  background-color: #fff;
  max-width: 500px;
}
.jtl-qrcode-component .bgCode {
  font-size: 14px;
  text-align: center;
}
.jtl-qrcode-component .bgCode .top-title {
  border-bottom: 1px solid #dcdcdc;
  height: 60px;
  line-height: 60px;
  width: 100%;
  text-align: center;
}
.jtl-qrcode-component .bgCode .logo {
  display: inline-block;
  height: 40px;
}
.jtl-qrcode-component .bgCode .qrcode-wrapper {
  height: 200px;
  line-height: 200px;
  width: 100%;
  text-align: center;
}
.jtl-qrcode-component .bgCode .project-name {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 10px;
}
.jtl-qrcode-component .bgCode .content {
  border-top: 1px solid #dcdcdc;
  text-align: center;
  width: 100%;
  height: 60px;
}